import React from "react"
import { Link, graphql } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import parse from "html-react-parser"

// We're using Gutenberg so we need the block styles
// these are copied into this project due to a conflict in the postCSS
// version used by the Gatsby and @wordpress packages that causes build
// failures.
// @todo update this once @wordpress upgrades their postcss version
import "../css/@wordpress/block-library/build-style/style.css"
import "../css/@wordpress/block-library/build-style/theme.css"

import Layout from "../components/layout"
import Seo from "../components/seo"

const BlogPostTemplate = ({ data: { previous, next, post } }) => {
  const featuredImage = {
    fluid: getImage(post.featuredImage?.node.localFile),
    alt: post.featuredImage?.node?.alt || ``,
  }
  const nextFeaturedImage = {
    fluid: getImage(next?.featuredImage?.node.localFile) || "",
    alt: next?.featuredImage?.node?.altText || post.title,
  }
  const prevFeaturedImage = {
    fluid: getImage(previous?.featuredImage?.node.localFile) || "",
    alt: previous?.featuredImage?.node?.altText || post.title,
  }

  return (
    <Layout>
      <div
        className={
          post.pageACF.colorScheme + " h-[200px] featured-image-wrapper"
        }
      >
        {featuredImage?.fluid && (
          <div className="featured-image absolute -z-20 h-[500px] md:h-[700px] lg:h-[1024px] w-full overflow-x-hidden">
            <GatsbyImage
              image={featuredImage.fluid}
              alt={featuredImage.alt}
              className="!absolute -z-20 !w-screen"
            />
            <div className="absolute -z-10 h-full w-full mix-blend-screen"></div>

            <div
              className="absolute h-[200px] sm:h-[150px] md:h-[200px] lg:h-[400px] bottom-0 w-full bg-contain"
              style={{
                backgroundImage:
                  "url(https://wp.transtechsocial.org/wp-content/uploads/2023/01/white-fade-2.png)",
              }}
            ></div>
          </div>
        )}
      </div>
      <div className={post.pageACF.colorScheme + " single single-post"}>
        <article
          id="page-content"
          className="max-w-7xl mx-auto px-4 sm:px-8"
          itemScope
          itemType="http://schema.org/Article"
        >
          <div className="w-full">
            <h1
              itemProp="headline"
              className="w-full font-hero text-4xl md:text-6xl lg:text-7xl leading-none text-black mb-4 md:mb-12 lg:mb-24"
            >
              {parse(post.title)}
            </h1>
          </div>
          <div className="entry-content relative wp-block-columns mb-32">
            <section
              id="blocks"
              itemProp="articleBody"
              className="is-layout-flow wp-block-column"
              style={{ flexBasis: "66.66%" }}
            >
              {post.categories?.nodes.map(
                category =>
                  category.name !== "Workshops" &&
                  category.name !== "Feature" && (
                    <p className="text-ttDarkGray">{post.date}</p>
                  )
              )}
              {!!post.content && parse(post.content)}
              <div className="wp-block-separator is-style-wide"></div>
              <h2>Continue Reading</h2>
              <nav className="blog-post-nav grid sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 justify-between items-stretch gap-8 mb-4">
                {previous && prevFeaturedImage ? (
                  <>
                    <article
                      key={previous.uri}
                      itemScope
                      itemType="http://schema.org/Article"
                      className="relative flex flex-col justify-end rounded-lg"
                    >
                      <div className="absolute w-full p-4 bg-gradient-to-b from-transparent to-ttBlack80 rounded-b-lg">
                        <span className="text-ttGray">&larr; Previous</span>
                        <h5
                          className="font-bold !text-white text-lg md:text-2xl lg:text-xl xl:text-2xl mb-2"
                          itemProp="headline"
                        >
                          {parse(previous.title)}
                        </h5>
                        <small className="text-ttGray uppercase">
                          {previous.date}
                        </small>
                      </div>
                      <GatsbyImage
                        image={prevFeaturedImage.fluid}
                        alt={prevFeaturedImage.alt}
                        className="relative -z-10 mb-0 drop-shadow-harsh border-2 border-black rounded-lg"
                      />
                      <Link
                        to={previous.uri}
                        className="absolute h-full w-full z-10"
                      ></Link>
                    </article>
                  </>
                ) : (
                  <div></div>
                )}
                {next && nextFeaturedImage ? (
                  <>
                    <article
                      key={next.uri}
                      itemScope
                      itemType="http://schema.org/Article"
                      className="relative flex flex-col justify-end rounded-lg"
                    >
                      <div className="absolute w-full p-4 bg-gradient-to-b from-transparent to-ttBlack80 rounded-b-lg">
                        <span className="text-ttGray">Next &rarr;</span>
                        <h5
                          className="font-bold !text-white text-lg md:text-2xl lg:text-xl xl:text-2xl mb-2"
                          itemProp="headline"
                        >
                          {parse(next.title)}
                        </h5>
                        <small className="text-ttGray uppercase">
                          {next.date}
                        </small>
                      </div>
                      <GatsbyImage
                        image={nextFeaturedImage.fluid}
                        alt={nextFeaturedImage.alt}
                        className="relative -z-10 mb-0 drop-shadow-harsh border-2 border-black rounded-lg"
                      />
                      <Link
                        to={next.uri}
                        className="absolute h-full w-full z-10"
                      ></Link>
                    </article>
                  </>
                ) : (
                  <div></div>
                )}
              </nav>
            </section>
            <div
              className="is-layout-flow wp-block-column subnav"
              style={{ flexBasis: "33.33%" }}
            >
              <h3>Continue Reading</h3>
              <ul>
                <li>
                  <Link to="/news/">News</Link>
                </li>
                <li>
                  <Link to="/essays/">Essays</Link>
                </li>
              </ul>
              <br />
              <br />
              <h3>Membership</h3>
              <ul>
                <li>
                  <Link to="/programs/">Programs</Link>
                </li>
                <li>
                  <Link to="/essays/">Membership Levels</Link>
                </li>
                <li>
                  <Link to="/workshops/">Workshops</Link>
                </li>
              </ul>
              <div className="is-content-justification-center is-layout-flex wp-container-25 wp-block-buttons">
                <div className="wp-block-button">
                  <Link
                    className="wp-block-button__link has-background wp-element-button bg-gradient-to-r from-ttGreen to-ttYellow"
                    to="/membership-levels/"
                  >
                    Join TransTech
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </article>
      </div>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostById(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    post: wpPost(id: { eq: $id }) {
      id
      excerpt
      content
      title
      date(formatString: "MMMM DD, YYYY")
      pageACF {
        colorScheme
      }
      categories {
        nodes {
          name
        }
      }
      featuredImage {
        node {
          altText
          localFile {
            url
            childImageSharp {
              gatsbyImageData(
                webpOptions: { quality: 90 }
                width: 1920
                formats: WEBP
                layout: FIXED
                aspectRatio: 2
              )
            }
          }
        }
      }
      smartcrawlSeo {
        metaDesc
        title
        opengraphTitle
        opengraphDescription
        opengraphImage {
          mediaItemUrl
        }
        twitterTitle
        twitterDescription
        twitterImage {
          mediaItemUrl
        }
      }
    }
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
      date(formatString: "MMMM DD, YYYY")
      featuredImage {
        node {
          altText
          localFile {
            url
            childImageSharp {
              gatsbyImageData(
                webpOptions: { quality: 90 }
                width: 716
                formats: WEBP
                layout: CONSTRAINED
                aspectRatio: 1.55
              )
            }
          }
        }
      }
    }
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
      date(formatString: "MMMM DD, YYYY")
      featuredImage {
        node {
          altText
          localFile {
            url

            childImageSharp {
              gatsbyImageData(
                webpOptions: { quality: 90 }
                width: 800
                formats: WEBP
                layout: CONSTRAINED
                aspectRatio: 1.55
              )
            }
          }
        }
      }
    }
  }
`

export const Head = ({ data: { post } }) => {
  return (
    <Seo
      title={parse(post.title) + " | TransTech Summit"}
      description={post.smartcrawlSeo?.metaDesc}
      ogTitle={post.opengraphTitle}
      ogDescription={post.smartcrawlSeo?.opengraphDescription}
      twTitle={post.smartcrawlSeo?.twitterTitle}
      twDescription={post.smartcrawlSeo?.twitterDescription}
      featuredImage={post.featuredImage?.node?.mediaItemUrl}
      ogImage={post.smartcrawlSeo?.opengraphImage?.mediaItemUrl}
      twImage={post.smartcrawlSeo?.twitterImage?.mediaItemUrl}
    />
  )
}
